define(['lodash', 'santa-components', 'coreUtils', 'buttonCommon', 'skins', 'adminLoginButton/skins/skins.json', 'componentsCore'], function (_, santaComponents, coreUtils, buttonCommon, skinsPackage, skinsJson, componentsCore) {
    'use strict';

    const SantaTypes = santaComponents.santaTypesDefinitions;
    const baseEditorUrl = 'http://editor.wix.com/html/editor/web/renderer/edit/';

    /**
     * A heuristic to generate a string which will be common to server and client during SSR,
     * but will be different in every new execution of santa render.
     * In the future this could be set in rendererModel by server.
     */
    function createSessionGUID(siteMediaToken, siteId) {
        const str = siteMediaToken.split('.')[2] || siteId;
        return coreUtils.guidUtils.nameUUIDFromString(str);
    }

    function getEditorUrl(siteId, metaSiteId, siteMediaToken) {
        const editorSessionId = createSessionGUID(siteMediaToken, siteId);
        return `${baseEditorUrl + siteId}?metaSiteId=${metaSiteId}&editorSessionId=${editorSessionId}`;
    }

    //eslint-disable-line santa/no-module-state
    const comp = {
        displayName: 'AdminLoginButton',
        mixins: [
            componentsCore.mixins.skinBasedComp,
            santaComponents.mixins.animationsMixin,
            buttonCommon.buttonMixin
        ],
        propTypes: {
            compData: SantaTypes.Component.compData.isRequired,
            style: SantaTypes.Component.style.isRequired,
            siteId: SantaTypes.RendererModel.siteId.isRequired,
            metaSiteId: SantaTypes.RendererModel.metaSiteId.isRequired,
            siteMediaToken: SantaTypes.RendererModel.siteMediaToken
        },
        statics: {
            compSpecificIsDomOnlyOverride() {
                return false;
            }
        },
        measureComponent(node) {
            const linkSkinPart = node.ownerDocument.getElementById(`${this.props.id}link`);
            return linkSkinPart ? [{node, type: 'css', changes: {width: linkSkinPart.offsetWidth}}] : [];
        },
        getSkinProperties() {
            const skinProps = {
                label: {
                    style: _.merge(
                        this.getLabelStyle(),
                        {lineHeight: coreUtils.style.unitize(this.props.style.height)}
                    ),
                    children: [this.props.compData.label]
                },
                link: {
                    target: '_self',
                    href: getEditorUrl(this.props.siteId, this.props.metaSiteId, this.props.siteMediaToken)
                }
            };

            this.resetMinHeightIfNeeded(skinProps);
            return skinProps;
        }
    };

    // And register yourself
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.AdminLoginButton', comp);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return comp;
});
