(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.AdminLoginSkin'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {},
      [
        "div",
        "dialog",
        [
          "_content"
        ],
        {},
        [
          "div",
          "xButton",
          [],
          {}
        ],
        [
          "div",
          "header",
          [],
          {},
          [
            "a",
            null,
            [],
            {
              "href": "http://www.wix.com",
              "target": "_blank"
            },
            [
              "img",
              "favIcon",
              [],
              {
                "src": "http://www.wix.com/favicon.ico",
                "alt": "Administator Login"
              }
            ]
          ]
        ],
        [
          "div",
          null,
          [
            "_controlContent"
          ],
          {},
          [
            "input",
            "passwordInput",
            [],
            {
              "type": "password",
              "placeholder": "Password"
            }
          ],
          [
            "input",
            "submitButton",
            [],
            {
              "type": "button",
              "value": "GO"
            }
          ]
        ],
        [
          "div",
          "error",
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "tdr": "URL",
    "bg": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "tdr": "WEB_THEME_DIRECTORY",
    "bg": "#000"
  },
  "paramsMutators": {
    "bg": {
      "type": "alpha",
      "value": 0.75
    }
  },
  "css": {
    "%": "position:fixed;width:100%;height:100%;z-index:10;",
    "%blockingLayer": "background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;",
    "%dialog": "[rd]  background-color:#FAFAFA;width:295px;height:90px;position:absolute;padding-left:27px;padding-top:14px;",
    "%_controlContent": "margin-top:10px;",
    "%header": "margin-bottom:12px;position:relative;width:200px;",
    "%xButton": "position:absolute;top:12px;right:12px;cursor:pointer;background:url([tdr]/x.png) no-repeat;height:10px;width:10px;",
    "%passwordInput": "margin-right:7px;padding:10px;border:solid 1px #CCCCCC;font:14px Arial;font-style:italic;color:#9D9D9D;height:30px;width:202px;",
    "%favIcon": "vertical-align:top;width:16px;height:16px;",
    "%submitButton": "cursor:pointer;border:solid 1px #5B8FA7;border-radius:5px;background-color:#28A6E9;height:30px;width:60px;color:#FFFFFF;font:18px Arial;position:relative;top:-10px;",
    "%submitButton:hover": "background-color:#137BA8;",
    "%[data-state=\"normal\"] %error": "display:none;",
    "%[data-state=\"error\"] %error": "display:block;font:italic 12px Arial;color:#FD0B0B;margin-top:5px;",
    "% h1": "display:inline;font:16px Arial;font-weight:lighter;color:#9D9D9D;"
  }
}
 skins['wysiwyg.viewer.skins.button.AdminLoginButtonSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ],
    [
      "div",
      "dialog",
      [],
      {}
    ]
  ],
  "params": {
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "trans": "TRANSITION",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "fnt": "font_9",
    "txt": "color_15",
    "trans": "color 0.4s ease 0s",
    "txth": "color_1"
  },
  "css": {
    "%": "min-height:17px;display:table;",
    "%link": "text-align:center;display:block;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;[trans]",
    "% a:hover span": "color:[txth];[trans]"
  }
}

        return skins;
    }));